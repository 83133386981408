@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  color: black;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gradient-to-r from-sky-500 to-indigo-600 rounded-lg;
}

.MuiFormLabel-root.Mui-error {
  @apply text-zinc-100;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.MuiFormHelperText-root.Mui-error.Mui-disabled {
  background: none;
  margin: 2px;
}
